.test{
    display: grid;
    grid-template-columns: 1fr 1fr ;
 justify-content: space-between;
    padding: 20px;
}
.sec1{
    display: grid;
    grid-template-columns: 30px 20px;
    grid-template: 10px;
}
/* .sec{
    display: flex;
    flex-direction: row;
    justify-content:end;
    align-items: center;
  
} */
 
/* .sec2{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-template: 10px;
} */