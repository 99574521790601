.my-modal {
    width: 80vw;
    max-width: 80vw;
} 


#hidden-file-input {
    position: absolute;
    top: -9999px;
    left: -9999px;
}
