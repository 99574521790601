.wizard_form {
    margin: 0 auto;
    padding: 20px;
  }
  
  .wizard_form h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .wizard_form .col-form-label {
    font-weight: bold;
    margin-bottom: 0;
  }
  
  .wizard_form .form-control {
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    background-color: #f8f9fa;
  }
  
  .wizard_form .form-control:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .wizard_form .mb-2 {
    margin-bottom: 10px;
  }
  
  .wizard_form .btn {
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: bold;
  }
  
  .wizard_form .mb-10 {
    margin-bottom: 20px;
  }

  /* .abs-btn{
    position: absolute !important;
    bottom: 43px !important;
    right: 45px !important;
    border-radius: 25px !important;
    background-color: black !important;
  }

  .con-btn{
    border-radius: 25px !important;
  } */

  .form-group.required .control-label:after {
    content:"*" !important;
    color:red !important;
  }